type WorkData = {
    timeRange: string,
    title: string,
    subTitle: string,
    description?: string,
    bullets?: string[],
    hideForPrint?: true,
    hide?: true
}

export const workData: WorkData[] = [{
    timeRange: "Sept. 2021 – heute",
    title: "MAS Assistenz",
    subTitle: 'Assistenz des MAS "Software Engineering" bei der Ostschweizer Fachhochschule (früher HSR).'
}, {
    timeRange: "Jan. 2019 – heute",
    title: "Senior Frontend Engineer",
    subTitle: 'Freelancer @SRF Schweizer Radio Fernsehen',
    bullets: [
        'SRG Mediathek (Play), eine Plattform mit Livestreams, Videos und weiteren Funktionen. Für die verschiedenen Geschäftseinheiten der SRG haben wir eine gemeinsame Codebasis für alle Sprachregionen geschaffen, dabei darauf geachtet, dass jede Region unterschiedliche Funktionen anbieten kann. In der Rolle als Leadentwickler habe ich Play zu einer hochkonfigurativen Anwendung mit "Server Side Rendering" entwickelt, unter Verwendung modernster Technologien wie TypeScript, Styled-Components und React. Accessibility, SEO und verständliches UX in verschiedenen Sprachen waren mir besonders wichtig. Durch PaaS-Nutzung, Testlink-Generierung und verschiedene Tests habe ich die gewünschte Qualität erreicht.',
        'Als Greenfield-Projekt erschufen wir ein hochskalierbares mandantenfähiges Content Management System (CMS) für die Mediatheken der SRG. Dieses ermöglicht effiziente Inhaltsverwaltung und Kontrolle über verschiedene Webseiten, Apps und TVos. Das CMS bietet eine umfassende Kuratierung dynamischer Inhalte mit verschiedenen Layout-Optionen. Durch eine ausführliche Konzeptionsphase, eine fundierte Architektur und deren Einsatz von TypeScript, Styled-Components sowie React habe ich eine solide Grundlage für die Entwicklung des Produktes geschaffen. Funktionen wie automatisiertes zeitgesteuertes Aufschalten von Content, unterschiedliche Benutzerberechtigungen und Metadatenmodifikation ermöglichen eine individuelle Nutzererfahrung. Unmittelbar nach der ersten Veröffentlichung unseres Produkts wurden auch Inhalte von anderen Applikationen über unsere CMS-Lösung kuratiert.',
        'Für ein gutes Jahr unterstützte ich die Backend-Kollegen bei der Entwicklung eines neuen leistungsfähigen Integrationslayers, der Daten aus verschiedenen Sprachregionen vereinheitlicht. Die Applikation integriert Informationen aus unterschiedlichen Umsystemen und setzt Technologien wie Kotlin/Java, Micronaut sowie eine Postgres-Datenbank ein.',
        'Ich entwickelte einen budgetfreundlichen Image-Service für Play SRG, basierend auf AWS und Rokka. Dieser Service bietet den Konsumenten neuste Bildformate in unterschiedlichen Auflösungen an. Dies verbesserte nicht nur die SEO der Webseite, sondern auch das visuelle Erlebnis auf den Plattformen.',
        'Einen Lehrling pro Jahr betreute ich während dessen Abschlussjahr. Zudem übernahm ich die Funktion als Fachvorgesetzter für die individuelle praktische Arbeit mit Schwerpunkt auf der fachlicher Anleitung und Förderung der beruflichen Entwicklung.',
        'Ich begleitete ein SRF-Entwickler-Team vier Monate lang als Berater und Entwickler während eines Software-Nomadentums. Dabei leitete ich die Migration einer bestehenden JavaScript-Applikation nach TypeScript.'
    ]
}, {
    timeRange: "2020 - 2022",
    title: "Lehrlingsbetreuung und Fachvorgesetzter",
    subTitle: 'Freelancer @SRF Schweizer Radio Fernsehen',
    description: 'Einen Lehrling pro Jahr betreute ich während dessen Abschlussjahr. Zudem übernahm ich die Funktion als Fachvorgesetzter für die individuelle praktische Arbeit mit Schwerpunkt auf der fachlicher Anleitung und Förderung der beruflichen Entwicklung.',
    hideForPrint: true
}, {
    timeRange: "Juni 2016 – Jan. 2019",
    title: "Senior Frontend Engineer",
    subTitle: 'Helsana AG', description: 'Entwicklung, Design, Konzeption von Grossapplikationen',
    bullets:[
        'Überarbeitung des Kundenportals für Privatkunden, einschliesslich der Umstellung von einem statischen Design auf ein neues Responsive Design.',
        'Das End-to-End-Testsetup mit Protractor und Selenium-Grid habe ich aufgebaut und implementiert. Gleichzeitig habe ich das Kundenportal um weitere Funktionalitäten unter Verwendung von Angular, sowie die dazugehörigen Qualitätsprüfungen, mittels Karma und Jasmine ausgebaut.',
        'Eigenverantwortliche Umsetzung einer ganzheitlichen Deployment-Pipeline zur Realisierung von Continuous Delivery.',
        'Durchführung von Performancevergleichsmessungen mit Gatling, um Engpässe zu erkennen und gezielte Optimierungen vorzunehmen.',
        'Erstellung einer interaktive Weblösung zum Zusammenstellen einer Offerte zu Krankentaggeld und Unfallversicherung mittels Technologien wie Angular und SASS'
    ]
}, {
    timeRange: "Jan. 2015 – Mai 2016",
    title: "Informatik Consultant",
    subTitle: 'ERNI Consulting AG', description: 'Entwickeln und Testen von Softwarelösungen',
    bullets:[
        'Mitentwicklung eines Web-Portals, welches Businesskunden Rechnungen, Wartungen, sowie Services übersichtlich anzeigt. Die Applikation ist mandantenfähig,  ist im Frontend mit Angular.js und im Backend mit Java umgesetzt. Ich setzte mich dabei mehrheitlich mit der Frontendentwicklung unter Berücksichtigung der existierenden Performance- und Qualitätsstandards auseinander.'
    ]
}, {
    timeRange: "Aug. 2010 – Juni 2011",
    title: "Betriebselektriker",
    subTitle: 'Ferag',
    description: 'Montage und Inbetriebnahme von Elektro-Anlagen, Unterhalt und Reparaturen an Produktionsmaschinen',
    hide: true
}]
