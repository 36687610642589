type ListData = { title: string, skills: string[] }[];

export const skillList: ListData = [{
    title: 'Technologien', skills: [
        "TypeScript",
        "JavaScript",
        "Node.js",
        "HTML",
        "CSS",
        "SCSS",
        "JSS",
        "React",
        "Angular",
        "Kotlin",
        "Git",
        "Rest",
        "MongoDB",
        "WebSockets"]
}, {
    title: 'Building', skills: ["Vite", "Webpack", "Gulp", "Grunt"]
}, {
    title: 'Testing', skills: ["Jest", "Playwright",
        "Protractor",
        "Cypress",
        "Selenium Grid",
        "Karma",
        "Jasmine",
        "Gatling"]
}, {
    title: 'Weiteres', skills: [
        "Professional Scrum Master I",
        "npm",
        "yarn",
        "Accessibility",
        "SEO",
        "Deployment Pipeline",
        "Heroku",
        "Github Actions",
        "AWS Lambda",
        "AWS Gateway",
        "BizDevOps",
        "Postman",
    ]
}]