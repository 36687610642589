type ProjectData = {
    timeRange: string,
    title: string,
    description: string,
    bullets?: string[]
    link?: { href: string, text: string },
    hide?: true
}

export const projectData: ProjectData[] = [{
    timeRange: "Feb. 2019 - heute",
    title: "Play SRG, Mediathek und Streamingplattform",
    description: 'Play bietet neben dem Konsum von Livestreams und Videos auch diverse andere Funktionen wie z.B. ein Login, ein TV-Programm, die Möglichkeit Favoriten zu hinterlegen und bietet Empfehlungen an. Die Plattform gibt es in allen vier Landessprachen sowie auch in englischer Ausführung.\n\n Bei der Entwicklung nahm ich die führende Rolle im Frontend-Engineering ein. Accessiblity, SEO und verständliches UX in einfacher Sprache war nicht nur eine Anforderung des Kunden, sondern ist auch mir ein besonderes Anliegen. \n\nFür die verschiedenen Geschäftseinheiten der SRG haben wir eine gemeinsame Codebasis für alle Sprachregionen erstellt. Die Applikation wird jedoch in jeder Region unterschiedlich dargestellt und bietet den Nutzern verschiedene Funktionen. Meine Aufgabe war es sicherzustellen, dass trotz der gemeinsamen Codebasis alle Sprachregionen so unabhängig wie möglich funktionieren. In der Rolle als Leadentwickler habe ich Play zu einer hochkonfigurativen Applikation mit eigenem "Server Side Rendering" gemacht, wobei wir modernste Technologien und Frameworks wie TypeScript, Styled-Components und React verwendet haben. Durch die Nutzung eines PaaS-Providers, die Generierung von Testlinks für jedes neue Feature und die Durchführung verschiedener Unit- und E2E-Tests konnte die gewünschte Qualität und Quantität erreicht werden.',
    link: {href: "https://www.srf.ch/play/tv", text: "Play SRF"}
}, {
    timeRange: "Okt. 2019 - heute",
    title: "Play Application Configuration (PAC)",
    description: 'In meiner Funktion als Webengineer habe ich erfolgreich ein skalierbares Content Management System (CMS) für die Mediathek von Play SRF entwickelt und optimiert. Diese Anwendung ermöglicht nicht nur die effiziente Verwaltung der Inhalte innerhalb der Play SRF Mediathek, sondern erlaubt auch die Verwaltung von Inhalten anderer Webseiten. Eine zentrale Funktion dieses CMS besteht in der umfassenden Kuratierung von unterschiedlichen Seiten mit dynamischem Inhalt, wobei unterschiedliche Layout-Typen für eine ansprechende visuelle Präsentation integriert wurden.\n' +
        '\n' +
        'Die eingesetzten Technologien für die Entwicklung dieser Anwendung umfassen TypeScript, Styled-Components und React. Durch den Einsatz dieser modernen Technologien habe ich eine solide Grundlage für die Entwicklung einer benutzerfreundlichen CMS-Lösung geschaffen. Die Anwendung bietet zudem diverse Einstellungen, darunter Aufschaltzeiten für die Veröffentlichung von Inhalten, differenzierte Berechtigungen für Nutzergruppen, sowie die Möglichkeit zur Metadatenmodifikation. Diese Funktionen ermöglichen es, die Inhalte auf vielfältige Weise zu steuern und eine massgeschneiderte Nutzererfahrung zu schaffen, was sie zu einem wertvollen Instrument für die Mediathek von Play SRF macht.',
}, {
    timeRange: "Sept. 2022 - Okt. 2023",
    title: "Unterstützung im Backend für SRF Play & Play Application Configuration (PAC)",
    description: 'Ich unterstützte die Backend-Kollegen bei der Entwicklung eines leistungsfähigen Integrationslayers, der Daten aus verschiedenen Sprachregionen vereinheitlicht. Die Applikation integriert Informationen aus unterschiedlichen Umsystemen und setzt Technologien wie Kotlin/Java, Micronaut sowie eine Postgres-Datenbank ein.',
}, {
    timeRange: "Apr. 2022 - Aug. 2022",
    title: "Software-Nomadentum bei SRF",
    description: 'Während vier Monaten hatte ich die Möglichkeit, ein anderes Team bei SRF im Rahmen eines Software-Nomadentums zu unterstützen. In dieser Funktion als Berater und Entwickler führte ich das Team in der Migration einer bestehenden Applikation von JavaScript nach TypeScript.',
}, {
    timeRange: "Feb. 2022 - März 2022",
    title: "Entwicklung eines Image-Service",
    description: 'Ich habe ein Kurzprojekt gestartet, um einen Image-Service für Play SRG zu entwickeln. Dabei habe ich AWS Services und Rokka genutzt, wichtig war mir, dass es budgetfreundlich bleibt und die neuesten Bildformate unterstützt werden. Nebst dem, dass es der Webseite SEO-Technische Vorteile verschafte, hatte der Service auch dazu beigetragen, das visuelle Erlebnis auf den Plattformen zu verbessern.',
}, {
    timeRange: "April 2018 - Jan. 2019",
    title: "Kundenportal für Privatkunden nach BizDevOps",
    description: 'Im Rahmen dieses Projektes wurde der Ansatz von BizDevOps verfolgt. Dabei übernahm ich den Lead sowohl im Operations-Teil, als auch in der Frontend-Entwicklung. Dieses Projekt ist die logische Fortsetzung des Projektes “Helsana Privat-Kundenportal” und basiert darauf. \n \nMit dem Fokus in der Entwicklung habe ich das End-To-End-Testsetup mit Protractor und Selenium-Grid aufgebaut und implementiert, sowie Kernfunktionalitäten und die dazugehörenden Qualitätsprüfungen (mittels Karma und Jasmine) weiter entwickelt. Dabei haben wir konsequent auf die Gitlab-Runner-Pipeline gesetzt. Die Testabdeckung wurde automatisiert durch einen hohen Schwellwert validiert. Des Weiteren habe ich die ganze Deployment Pipeline umgesetzt um ein Continuous Delivery zu ermöglichen diese verfolgt das Gitflow Branching Model. Performancevergleichsmessungen habe ich mit Gatling durchgeführt, um Engpässe zu erkennen und aufzeigen zu können. Um die durchgängige Funktionalität auf den Browsern zu gewährleisten, setzte ich mich stark mit dessen Kompatibilitäten auseinander und arbeitete eng mit unseren Nevis-Spezialisten zusammen.'
}, {
    timeRange: "Nov. 2017 - April 2018",
    title: "Kundenportal für Privatkunden",
    description: 'Die Applikation bietet Privatkunden die Möglichkeit, ihre hoch sensitiven Daten zu bearbeiten und Auszüge wie z.B. Leisungsabrechungen anzusehen. Kunden können dabei Aufträge oder Rechnungen erfassen und Fragen zu Dokumenten online erfassen. Das Kundenportal wurde selbständig von einem statischen Design in ein komplett neues Responsive Design überarbeitet. Danach initiierte und plante ich eine Umstellung vom alten JSP-Stack zum neuen Angular-Framework und übernahm dort die Funktion des Lead-Entwicklers.'
}, {
    timeRange: "Sept. 2016 – Nov. 2017",
    title: "Prämienrechner für Businesskunden",
    description: 'Interaktive Weblösung zum Zusammenstellen einer Offerte zu Krankentaggeld und Unfallversicherung'
}, {
    timeRange: "Juni 2016 – Sept. 2016",
    title: "Prämienrechner-Widget für Privatkunden ",
    description: 'Web-Widget zur Prämienberechnung für Privatkunden'
}, {
    timeRange: "Juni 2015 – Mai 2016",
    title: "Enterprise Business Center",
    description: 'Das Portal zeigt Businesskunden der Swisscom Rechnungen, Wartungen, sowie Services übersichtlich an. Hierfür wurde diese mandantenfähige Applikation von mir mitentwickelt. Die Applikation ist im Frontend mit Angular.js und im Backend mit Java umgesetzt. Ich setzte mich dabei mehrheitlich mit der Frontendentwicklung unter Berücksichtigung der existierenden Performance- und Qualitätsstandards auseinander. Ein wichtiger Aspekt der Arbeit war die nahe Zusammenarbeit mit dem Kunden, z.B. um die Usability zu optimieren.'
}, {
    timeRange: "Jan. 2015 – Juni 2015",
    title: "Cloudlösung für Privatkunden - MyDigital Life",
    description: 'Automatisiertes Testen der Weboberfläche sowie Analyse von Last und Performance der Cloudlösung',
    hide: true
},]
